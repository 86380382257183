.hero-bg {
  background-image: url("./assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 20em;
  width: 100%;
}

.red {
  color: red;
}
.bg-red {
  background-color: red;
}
.box_about_bg {
  background-color: rgba(243, 240, 240, 0.336);
  box-shadow: 1px 4px 6px 1px rgba(0, 0, 0, 0.116);
}

.about_hero {
  background-image: url("./assets/hero-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 20em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
  text-align: center; /* Centers text */
}

.about_hero_text {
  font-size: 80px;
  font-style: italic;
}

.cursor {
  cursor: pointer;
}

.navbar {
  background-color: #000000;
  color: #ffffff;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo {
  font-size: 24px;
  font-weight: bold;
}

.navbar-toggle {
  font-size: 24px;
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: none;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.navbar-item a {
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  transition: color 0.3s;
}

.navbar-item a:hover {
  color: #006eff; /* Gold color on hover */
}
.hero_text {
  font-size: 70px;
  font-weight: 700;
  margin-top: 40px;
}

.appointment_modal_parent {
  background-color: rgba(0, 0, 0, 0.527);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
}

.appointment_form_bg {
  background: white;
  height: 70vh;
  width: 100%;
  overflow-y: scroll;
  border-radius: 10px;
}

.reg_modal {
  background-color: rgba(0, 0, 0, 0.699);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  top: 0px;
}

.reg_modal_form_bg {
  background: #5979e4c7;
  height: 40vh;
  width: 100%;
  border-radius: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar-toggle {
    display: block;
  }

  .hero_text {
    font-size: 40px;
  }
  .extra {
    display: none;
  }
  .about_hero_text {
    font-size: 40px;
    font-style: italic;
  }
  .navbar-menu {
    flex-direction: column;
    background-color: #4a4a4a;
    position: absolute;
    top: 60px;
    right: 0;
    width: 200px;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .navbar-menu.open {
    display: block;
  }

  .navbar-item {
    padding: 10px 20px;
  }
}

.contact_input,
.contact_input {
  background-color: #43c61b00;
  border: none;
  border-bottom: 2px dotted #000;
  color: #857209;
  font-size: 20px;
  margin-top: 1.5em;
  outline: none;
  padding-bottom: 0.8em;
  padding-left: 0.5em;
  width: 100%;
}

.sendBtn {
  background-color: #18a1f0;
  border: none;
  border-radius: 50px;
  color: #fff;
  float: right;
  font-family: Euclid Circular A;
  height: 3.4em;
  outline: none;
  width: 8em;
}


/* blog
-------------------------------------------------- */

.landingBlog{
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;  /* Horizontal center */
  align-items: center;      /* Vertical center */
  height: 10em;            /* Full viewport height (optional, depending on the size you want) */
  text-align: center; 
}

.overflows{
  overflow-x: scroll;
}

/* /coments sections css properties
 */
 .comments{
  height: 10em;
 }
 .comments, .commentName{
  min-width: 100%;
  outline: none;
  border: none;
  background-color: #00000000;
  border-bottom: 1px solid black;
 }
